import React from 'react'
import 'normalize.css'
import { createGlobalStyle } from "styled-components"

import HeaderSection from './HeaderSection'
import FooterSection from './FooterSection'

const GlobalStyle = createGlobalStyle`
  :root {
    --body-font: "Lato", sans-serif;
    --heading-font: "DM Serif Display", serif;

    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;

    --black: #000;
    --white: #fff;
    --gray: #cdcdcd;
    --pink: #d41367;
    --green: #49b173;
    --blue: #375d9d;
    --red: #bf262a;
  }

  html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    background-color: var(--black);
  }

  body {
    background-color: var(--black);
    font-family: var(--body-font);
    font-weight: var(--font-regular);
    color: var(--white);
    font-size: 19px;
    line-height: 1.2;
    box-sizing: border-box;
    position: relative;

    @media(min-width: 768px) {
      font-size: 20px;
    }

    .container {
      margin: 0 auto;
      max-width: 1200px;
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;

      @media(min-width: 1240px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .row-two-col {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 50px;
      row-gap: 30px;

      @media(min-width: 992px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 100px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font);
      color: var(--white);
      line-height: 1.1;
      margin-top: 0;
      margin-bottom: 25px;
      font-weight: var(--font-regular);
    }

    h1 {
      font-size: 43px;
  
      @media(min-width: 768px) {
        font-size: 50px;
      }
    }

    h2 {
      font-size: 36px;
  
      @media(min-width: 768px) {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 30px;
  
      @media(min-width: 768px) {
        font-size: 33px;
      }
    }

    h4 {
      font-size: 26px;
  
      @media(min-width: 768px) {
        font-size: 28px;
      }
    }

    h5 {
      font-size: 23px;
    }

    h6 {
      font-size: 21px;
    }

    p {
      margin: 0 0 20px 0;
    }

    a {
      color: $pink;
      text-decoration: underline;
    }

    .btn {
      padding: 10px 25px;
      font-weight: var(--font-bold);
      font-size: 20px;
      line-height: 1;
      text-align: center;
      color: var(--black);
      display: inline-block;
      text-decoration: none;
      background: var(--white);
      border: 1px solid var(--pink);
      border-radius: 0;

      &:hover {
        background: var(--pink);
        color: var(--white);
        border: 1px solid var(--white);
      }
    }

    .btn-pink {
      color: var(--white);
      background: var(--pink);
      border: 1px solid var(--white);

      &:hover {
        color: var(--black);
        background: var(--white);
        border: 1px solid var(--pink);
      }
    }

    .btn-large {
      padding: 15px 45px;
    }
  }
`

export function Layout({ children }) {

  return (
    <>
      <GlobalStyle />
      <HeaderSection />
        {children}
      <FooterSection />
    </>
  );
}

export default Layout
