import React, { useState } from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Logo from '../images/fintech-law-logo.svg'
import MobileNav from '../images/icon-mobile-nav.svg'
import TwitterLogo from '../images/icon-twitter-gradient.svg'
import FacebookLogo from '../images/icon-facebook-gradient.svg'
import LinkedinLogo from '../images/icon-linkedin-gradient.svg'
import MobileLogo from '../images/fintech-law-logo.svg'
import MobileClose from '../images/icon-mobile-close.svg'
import MobileTwitterLogo from '../images/icon-twitter-gradient.svg'
import MobileFacebookLogo from '../images/icon-facebook-gradient.svg'
import MobileLinkedinLogo from '../images/icon-linkedin-gradient.svg'

const HeaderBar = styled.header`
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, var(--black), var(--black) 60%, rgba(0 0 0 / 0%));
  position: sticky;
  top: 0;
  z-index: 999;

  @media(min-width: 768px) {
    padding: 10px 40px;
  }
`

const Branding = styled.div`
  a {
    text-decoration: none;
    display: block;
    line-height: 1;
    height: 80px;
  }
`

const Navigation = styled.div`
  display: flex;  
  align-items: center;
  justify-content: flex-end;
  gap: 40px;

  nav {
    display: none;

    @media(min-width: 992px) {
      display: flex;
    }
  }
`

const NavBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 35px;
`

const NavDropdown = styled.li`
  position: relative;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover .sub-nav {
    opacity: 1;
    transition: top 0s linear 0s, opacity .3s ease .01s;
    top: 40px;
  }

  > a {
    color: var(--white);
    font-size: 17px;
    font-weight: var(--font-bold);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
      color: var(--gray);
    }
  }
`

const SubNav = styled.ul`
  opacity: 0;
  transition: opacity .3s ease 0s, transform 0s linear .9s;
  position: absolute;
  top: -1000px;
  background-color: var(--black);
  min-width: 200px;
  border: 2px solid var(--pink);
  padding: 12px;
  z-index: 1;
  list-style: none;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--white);
    font-size: 17px;
    font-weight: var(--font-bold);
    text-decoration: none;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }
`

const SocialButtons = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  @media(min-width: 992px) {
    display: flex;
  }

  a {
    line-height: 1;
  }
`

const MobileNavigation = styled.div`
  display: block;

  @media(min-width: 992px) {
    display: none;
  }
`

const MobileSlideIn = styled.div`
  position: absolute;
  top: 0;
  right: -320px;
  width: 320px;
  height: 100%;
  min-height: 100vh;
  z-index: 999;
  background-color: var(--black);
  border-left: 2px solid var(--pink);
  padding: 10px 25px 25px;
  overflow: auto;
  box-sizing: border-box;
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    display: block;
    right: 0;
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const MobileNavBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 30px 0;
  display: block;
`

const MobileNavDropdown = styled.li`
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;

  > a {
    color: var(--white);
    font-size: 22px;
    font-weight: var(--font-bold);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
      color: var(--gray);
    }
  }
`

const MobileSubNav = styled.ul`
  opacity: 1;
  list-style: none;
  padding-left: 10px;
  padding-top: 20px;

  li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--white);
    font-size: 19px;
    font-weight: var(--font-regular);
    text-decoration: none;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }
`

const MobileSocialButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  a {
    line-height: 1;
  }
`

export function Header() {

  const [isActive, setActive] = useState(false)

  const toggleClass = () => {
    setActive(!isActive)
  }

  const data = useStaticQuery(graphql`
    {
      MainNav: allContentfulNavLinksOrder(filter: {title: {eq: "Main Nav Links Order"}}) {
        edges {
          node {
            contentful_id
            title
            NavLinksOrder {
              contentful_id
              navLinkTitle
              navLinkUrl
              openLinkInNewTab
              navSubLinks {
                contentful_id
                navLinkTitle
                navLinkUrl
                openLinkInNewTab
              }
            }
          }
        }
      }
      HeaderSocial: allContentfulSocialMedia {
        edges {
          node {
            contentful_id
            facebookUrl
            jootUrl
            linkedInUrl
            twitterUrl
          }
        }
      }
    }
  `)

  return (
    <HeaderBar>
      <Branding>
        <a href='/'>
          <img src={Logo} width="158" height="80" alt="FinTech Law Logo" />
        </a>
      </Branding>
      <Navigation>
        <nav>
          {data.MainNav.edges.map(({ node }) => (
            <NavBar key={node.contentful_id}>
              {node.NavLinksOrder && node.NavLinksOrder.map((item) => (
                <NavDropdown key={item.contentful_id}>
                  <a href={item.navLinkUrl} target={item.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{item.navLinkTitle}</a>
                  {item.navSubLinks &&
                    <SubNav className="sub-nav">
                    {item.navSubLinks && item.navSubLinks.map((subitem) => (
                      <li key={subitem.contentful_id}>
                        <a href={subitem.navLinkUrl} target={subitem.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{subitem.navLinkTitle}</a>
                      </li>
                    ))}
                    </SubNav>
                  }
                </NavDropdown>
              ))}
            </NavBar>
          ))}
        </nav>
        {data.HeaderSocial.edges.map(({ node }) => (
          <SocialButtons key={node.contentful_id}>
            <a href={node.twitterUrl} target="_blank" rel="noreferrer">
              <img src={TwitterLogo} width="30" height="30" alt="Twitter Logo" />
            </a>
            <a href={node.facebookUrl} target="_blank" rel="noreferrer">
              <img src={FacebookLogo} width="30" height="30" alt="Facebook Logo" />
            </a>
            <a href={node.linkedInUrl} target="_blank" rel="noreferrer">
              <img src={LinkedinLogo} width="30" height="30" alt="Linkedin Logo" />
            </a>
            <a href={node.jootUrl} target="_blank" rel="noreferrer">
              <StaticImage
                src="../images/icon-joot-gradient.png"
                alt="Joot Logo"
                width={47}
                height={30}
              />
            </a>
          </SocialButtons>
        ))}
        <MobileNavigation>
          <div role="button" className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass} tabIndex={0}>
            <img src={MobileNav} width="30" height="12" alt="Mobile Menu Nav" />
          </div>
        </MobileNavigation>
      </Navigation>
      <MobileSlideIn className={isActive ? "active" : ""}>
        <div className="slide-in-content">
          <div className="mobile-header">
            <img src={MobileLogo} width="158" height="80" alt="FinTech Law Logo" />
            <div role="button" className={isActive ? "active" : ""} onClick={toggleClass} onKeyDown={toggleClass} tabIndex={0}>
              <img src={MobileClose} width="24" height="24" alt="Mobile Menu Nav" />
            </div>
          </div>
          <div className="mobile-nav">
            {data.MainNav.edges.map(({ node }) => (
              <MobileNavBar key={node.contentful_id}>
                {node.NavLinksOrder && node.NavLinksOrder.map((item) => (
                  <MobileNavDropdown key={item.contentful_id}>
                    <a href={item.navLinkUrl} target={item.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{item.navLinkTitle}</a>
                    {item.navSubLinks &&
                      <MobileSubNav className="sub-nav">
                      {item.navSubLinks && item.navSubLinks.map((subitem) => (
                        <li key={subitem.contentful_id}>
                          <a href={subitem.navLinkUrl} target={subitem.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{subitem.navLinkTitle}</a>
                        </li>
                      ))}
                      </MobileSubNav>
                    }
                  </MobileNavDropdown>
                ))}
              </MobileNavBar>
            ))}
          </div>
          {data.HeaderSocial.edges.map(({ node }) => (
            <MobileSocialButtons key={node.contentful_id}>
              <a href={node.twitterUrl} target="_blank" rel="noreferrer">
                <img src={MobileTwitterLogo} width="30" height="30" alt="Twitter Logo" />
              </a>
              <a href={node.facebookUrl} target="_blank" rel="noreferrer">
                <img src={MobileFacebookLogo} width="30" height="30" alt="Facebook Logo" />
              </a>
              <a href={node.linkedinUrl} target="_blank" rel="noreferrer">
                <img src={MobileLinkedinLogo} width="30" height="30" alt="Linkedin Logo" />
              </a>
              <StaticImage
                src="../images/icon-joot-gradient.png"
                alt="Joot Logo"
                width={47}
                height={30}
              />
            </MobileSocialButtons>
          ))}
        </div>
      </MobileSlideIn>
    </HeaderBar>
  );
}

export default Header
