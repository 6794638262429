import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import FooterBgImage from '../images/footer-bg.jpg'
import Logo from '../images/fintech-law-logo.svg'
import TwitterLogo from '../images/icon-twitter-gradient.svg'
import FacebookLogo from '../images/icon-facebook-gradient.svg'
import LinkedinLogo from '../images/icon-linkedin-gradient.svg'

const FooterTag = styled.footer`
  position: relative;
  background-image: url(${FooterBgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, var(--black), var(--black) 60%, rgba(0 0 0 / 0%));
  }
`

const FooterCols = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  margin-bottom: 40px;

  @media(min-width: 1100px) {
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    color: var(--white);
  }
`

const FooterColOne = styled.div`
  width: 100%;
  margin-bottom: 40px;
  text-align: center;

  @media(min-width: 1100px) {
    margin-bottom: 0;
    width: 265px;
    text-align: left;
  }

  img {
    margin-bottom: 25px;
  }

  p {
    color: var(--gray);
    font-size: 18px;
    line-height: 1.3;
    margin: 0 auto;
    max-width: 265px;

    @media(min-width: 1100px) {
      margin-bottom: 0;
    }
  }
`

const FooterColSet = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 80px;
  row-gap: 40px;
  margin: 0 auto;

  @media(min-width: 1100px) {
    justify-content: space-between;
    margin: 0;
  }

  h3 {
    font-size: 21px;
    font-weight: var(--font-bold);
    font-family: var(--body-font);
    text-transform: uppercase;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 18px;
        color: var(--gray);
        text-decoration: none;
        font-weight: var(--font-regular);

        &:hover {
          text-decoration: underline;
          color: var(--white);
        }
      }
    }
  }
`

const FooterColTwo = styled.div`
  text-align: center;

  @media(min-width: 480px) {
    max-width: 325px;
    text-align: left;
  }
  
  ul {
    column-count: 1;
    column-gap: 40px;

    @media(min-width: 480px) {
      column-count: 2;
    }
  }
`

const FooterColThree = styled.div`
  text-align: center;

  @media(min-width: 480px) {
    max-width: 125px;
    text-align: left;
  }
`

const FooterColFour = styled.div`
  text-align: center;

  @media(min-width: 480px) {
    max-width: 140px;
    text-align: left;
  }
`

const SocialButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media(min-width: 480px) {
    justify-content: flex-start;
  }

  a {
    line-height: 1;
  }
`

const Copyright = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  text-align: center;

  @media(min-width: 600px) {
    justify-content: space-between;
    text-align: left;
  }

  p {
    margin-bottom: 20px;
    font-size: 15px;
    color: var(--white);

    a {
      color: var(--white);
      text-decoration: underline;
    }
  }
`

export function Footer() {
  const data = useStaticQuery(graphql`
    {
      FooterServices: allContentfulNavLinksOrder(
        filter: {title: {eq: "Footer Services Link Order"}}
      ) {
        edges {
          node {
            contentful_id
            title
            NavLinksOrder {
              contentful_id
              navLinkTitle
              navLinkUrl
              openLinkInNewTab
            }
          }
        }
      }
      FooterResources: allContentfulNavLinksOrder(
        filter: {title: {eq: "Footer Resources Link Order"}}
      ) {
        edges {
          node {
            contentful_id
            title
            NavLinksOrder {
              contentful_id
              navLinkTitle
              navLinkUrl
              openLinkInNewTab
            }
          }
        }
      }
      FooterMisc: allContentfulFooter {
        edges {
          node {
            contentful_id
            brandingParagraph
            copyright
          }
        }
      }
      FooterSocial: allContentfulSocialMedia {
        edges {
          node {
            contentful_id
            facebookUrl
            jootUrl
            linkedInUrl
            twitterUrl
          }
        }
      }
    }
  `)

  return (
    <FooterTag>
      <div className="container">
        <FooterCols>
          <FooterColOne>
            <a href='/'>
              <img src={Logo} width="158" height="80" alt="FinTech Law Logo" />
            </a>
            {data.FooterMisc.edges.map(({ node }) => (
              <p key={node.contentful_id}>{node.brandingParagraph}</p>
            ))}
          </FooterColOne>
          <FooterColSet>
            <FooterColTwo>
              <h3>Services</h3>
              {data.FooterServices.edges.map(({ node }) => (
                <ul key={node.contentful_id}>
                  {node.NavLinksOrder && node.NavLinksOrder.map((item) => (
                    <li key={item.contentful_id}>
                      <a href={item.navLinkUrl} target={item.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{item.navLinkTitle}</a>
                    </li>
                  ))}
                </ul>
              ))}
            </FooterColTwo>
            <FooterColThree>
              <h3>Resources</h3>
              {data.FooterResources.edges.map(({ node }) => (
                <ul key={node.contentful_id}>
                  {node.NavLinksOrder && node.NavLinksOrder.map((item) => (
                    <li key={item.contentful_id}>
                      <a href={item.navLinkUrl} target={item.openLinkInNewTab ? '_blank" rel="noreferrer' : '_self'}>{item.navLinkTitle}</a>
                    </li>
                  ))}
                </ul>
              ))}
            </FooterColThree>
            <FooterColFour>
              <h3>Community</h3>
              {data.FooterSocial.edges.map(({ node }) => (
                <SocialButtons key={node.contentful_id} className="social-buttons">
                  <a href={node.twitterUrl} target="_blank" rel="noreferrer">
                    <img src={TwitterLogo} width="30" height="30" alt="Twitter Logo" />
                  </a>
                  <a href={node.facebookUrl} target="_blank" rel="noreferrer">
                    <img src={FacebookLogo} width="30" height="30" alt="Facebook Logo" />
                  </a>
                  <a href={node.linkedInUrl} target="_blank" rel="noreferrer">
                    <img src={LinkedinLogo} width="30" height="30" alt="Linkedin Logo" />
                  </a>
                  <a href={node.jootUrl} target="_blank" rel="noreferrer">
                    <StaticImage
                      src="../images/icon-joot-gradient.png"
                      alt="Joot Logo"
                      width={47}
                      height={30}
                    />
                  </a>
                </SocialButtons>
              ))}
            </FooterColFour>
          </FooterColSet>
        </FooterCols>
        {data.FooterMisc.edges.map(({ node }) => (
          <Copyright key={node.contentful_id}>
            <p>&copy; {new Date().getFullYear()} {node.copyright} - <Link to='/privacy-policy/'>Privacy Policy</Link> | <Link to='/terms-of-service/'>Terms Of Service</Link></p>
            <p>Built by <a href="https://codecoral.com" target="_blank" rel="noreferrer">Code Coral</a></p>
          </Copyright>
        ))}
      </div>
    </FooterTag>
  );
}

export default Footer