import React from 'react'
import { Script } from "gatsby"
import "@fontsource/lato/400.css"
import "@fontsource/lato/700.css"
import "@fontsource/dm-serif-display/400.css"

const Layout = require("./src/components/Layout").default

export function wrapPageElement({ element, props }) {

  return (
    <>
      <Layout {...props}>{element}</Layout>
      <Script id="mailerlite-forms-js">
        {`(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
        .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
        n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
        (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
        ml('account', '125745');`}
      </Script>
    </>
  )
}
